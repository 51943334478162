import React from "react"


import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  constructor(props) {
      super(props);
      this.state = { page: '' };
  }
  componentDidMount(){
    let url = window.location.href.split('/')[2]
    this.setState({page: url})
  }
  render() {
    let {page} = this.state
    return (<Layout>
      <SEO title="For Sale" />
      <h1>That's all Folks!</h1>
      <h2>Please head to <a href={"https://www.godaddy.com/domainsearch/find?checkAvail=1&tmskey=&domainToCheck="+ page}>GoDaddy</a> to
      purchase this domain.</h2>
      <p>Now go build something great.</p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
    </Layout>)
  }
}
// <Link to="/page-2/">Go to page 2</Link>
export default IndexPage
